import React, {useState} from 'react';
import s from './team.module.scss'
import {useMediaQuery} from "@mui/material";
import SvgSelector from "../SvgSelector";

const Team = ({art, img, members, pos, rev, id}) => {
  const [hover, setHover] = useState(false)
  const statusMobile = useMediaQuery(`(max-width: 520px)`);
  return (
    <div
      className={s.team}
      onMouseOut={() => (setHover(false))}
      onMouseOver={() => (setHover(true))}
      style={{
        flexDirection: !statusMobile ? 'column' : rev? 'row-reverse' : 'row'
      }}
      id={id}
    >
      <img src={img} alt={' '}/>
      <div className={s.text}>
        <h1>{art}</h1>
        <SvgSelector svg={'teams'}/>
      </div>

      <ul
        className={hover ? s.open : s.close}
        style={{
          top: statusMobile? '100%' : '60%',
          left: statusMobile? '' :  pos ? '' : '0%',
          right: statusMobile? '' :  pos ? '0%' : '',
          transformOrigin: pos ? 'right top' : 'left top'
        }}
      >
        {members.map((el, i) => (
          <li key={i}>{el}</li>
        ))}
      </ul>
    </div>
  );
};

export default Team;