import React, {useState} from 'react';
import s from './projectCard.module.scss'
import shadows from "@mui/material/styles/shadows";
import {Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, useMediaQuery, Zoom} from "@mui/material";
import {TabSlider} from "../Tabs/TabsSliderProject/TabsSlider";
import SvgSelector from "../SvgSelector";
const ProjectCard = ({img, name, team, png, idea, concept, photos, videos}) => {

  const [hover, setHover] = useState(false)
  const statusMobile = useMediaQuery(`(max-width: 520px)`);
  const [openModal, setOpenModal] = useState(false)
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div
        className={s.projectWrapper}
        onMouseOut={()=>(setHover(false))}
        onMouseOver={()=>(setHover(true))}
        onClick={()=>(setOpenModal(!openModal))}
        style={{
          backgroundColor: !statusMobile && hover ? png : 'rgba(0,0,0,0)',
          color: png ? '#262626' : '#FFF',
          overflow: png ? 'visible' : 'hidden',
          border: !statusMobile && png ? '2px solid #262626' : 'none',

        }}
      >
        {!statusMobile &&
          <p
            style={{
              opacity: hover ? '1' : '0',
              background: hover && !png ? 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5))' : ' '
            }}
          >
            <b>{name}</b>
            <br/>
            {team}
          </p>
        }
        <img
          src={img}
          alt={' '}
          style={{
            transform:!statusMobile && hover ? 'scale(1.2)' : '',
            transformOrigin: png ? 'bottom' : 'center',
            border: statusMobile && png ? '2px solid #262626' : 'none',
          }}
        />
        {statusMobile &&
          <div className={s.text}>
            <div>
              <p>
                <b>{name}</b>
                <br/>
                {team}
              </p>
            </div>
            <button>
              <p><b>Посмотреть</b></p>
            </button>
          </div>
        }
      </div>
      <Dialog
        TransitionComponent={Zoom}
        open={openModal}
        onClose={handleClose}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.35)',
          transition: '0.5s',
        }}

        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"

      >
        <DialogTitle id="scroll-dialog-title">
          <button
            onClick={()=>(setOpenModal(!openModal))}
          >
            <SvgSelector svg={'close'}/>
          </button>
        </DialogTitle>
        <DialogContent
          dividers={false}
        >
          <div id={'label'}>
            <div id={'left'}>
              <h1>{name}</h1>
              <h2>{team}</h2>
              <h3>Идея:</h3>
              <p>{idea}</p>
              <h3>Концепция:</h3>
              <p>{concept}</p>
            </div>
            <TabSlider dataIndex={photos}/>
          </div>
          <DialogContentText
            id="scroll-dialog-description"
            //tabIndex={-1}
          >
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>

  );
};

export default ProjectCard;