import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import s from "./slider.module.scss";
import {Navigation, Keyboard, Mousewheel} from "swiper/modules";
import {sliderData} from "./data";
import video from "../../../assets/blocks/block1.mov";
import React from "react";

export const TabSlider = ({dataIndex}) => {
  return (
    <Swiper
      loop={true}
      keyboard={{
        enabled: true,
      }}
      mousewheel={{
        releaseOnEdges: false,
        enabled: true,
        sensitivity: 4,
      }}
      modules={[Keyboard, Navigation, Mousewheel]}
      spaceBetween={"23"}
      navigation={true}
      className={s.slider}
    >
      {sliderData[dataIndex].map((imgUrl, i) => (
        <SwiperSlide className={s.slide} key={i}>
          {imgUrl.charAt(0) === 'i' ? (
            <img
              src={require("assets/tabs/sliders" +
                `/${dataIndex}/` +
                `${imgUrl}`)}
              alt=" "
            />
          ) : (
            <video
              src={require("assets/tabs/sliders" +
                `/${dataIndex}/` +
                `${imgUrl}`)}
              //type={"video/mp4"}
              typeof={'video/mp4'}
              controls={false}
              autoPlay={true}
              loop={true}
              muted={true}

              playsInline={true}
            />
          )}

        </SwiperSlide>
      ))}
    </Swiper>
  );
};
