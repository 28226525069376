import React from 'react';
import s from './block2.module.scss'
import img from '../../assets/blocks/sprayCans.png'
const Block2 = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.block1}>
        <h1>Почему именно Мурино?</h1>
        <div className={s.block1_wrapper}>
          <div className={s.text}>
            <p>
              Стрит-арт развивается в основном в центральной части Петербурга, почти не затрагивая районы отдаленные.
              Такие территории как <b>Мурино</b> как раз в большей степени нуждаются <b>в местах притяжений.</b>
            </p>
            <p>
              В Мурино проживает много <b>молодых людей,</b> в том участники нашей команды, которым хочется видеть больше
              <b> культурных и досуговых</b> мест в городе.
            </p>
          </div>
          <img src={img} alt={' '}/>
        </div>
      </div>

      <div className={s.block2}>
        <h1>Цели проекта</h1>
        <div className={s.tags}>
          <p className={s.tag1}>
            дать возможность местным жителям быть услышанными и <b>реализовать</b> свои <b>творческие идеи</b>
          </p>
          <p className={s.tag2}>
            <b>раскрыть</b> потенциал <br/> уличного искусства
          </p>
          <p className={s.tag3}>
            <b>подготовить фундамент</b>  для дальнейших изменений,
            в том числе появления новых арт-обьектов в Мурино
          </p>
        </div>
      </div>

    </div>
  );
};

export default Block2;