import React, {useState} from 'react';
import ProjectCard from "../projectCard/ProjectCard";
import s from './block5.module.scss'
import appImg from '../../assets/blocks/app.mp4'


import img11 from '../../assets/blocks/projects/1.1.png'
import img12 from '../../assets/blocks/projects/1.2.png'
import img13 from '../../assets/blocks/projects/1.3.png'
import img14 from '../../assets/blocks/projects/1.4.png'
import img15 from '../../assets/blocks/projects/1.5.png'
import img16 from '../../assets/blocks/projects/1.6.png'
import img17 from '../../assets/blocks/projects/1.7.png'

import img21 from '../../assets/blocks/projects/2.1.png'
import img22 from '../../assets/blocks/projects/2.2.png'
import img23 from '../../assets/blocks/projects/2.3.png'
import img24 from '../../assets/blocks/projects/2.4.png'
import img25 from '../../assets/blocks/projects/2.5.png'
import img26 from '../../assets/blocks/projects/2.6.png'
import img27 from '../../assets/blocks/projects/2.7.png'
import img28 from '../../assets/blocks/projects/2.8.png'
import img29 from '../../assets/blocks/projects/2.9.png'
import SvgSelector from "../SvgSelector";
import {useMediaQuery} from "@mui/material";
import {TabSlider} from "../Tabs/TabsSlider/TabsSlider";

const Block5 = () => {
  const statusMobile = useMediaQuery(`(max-width: 520px)`);
  const statusTablet = useMediaQuery(`(max-width: 900px)`);
  return (
    <div className={s.wrapper}>
      <div className={s.row1}>
        <h1>в итоге</h1>
        <p>Было разработано <b>15 проектов,</b> которые затрагивают не только <b> культурные и исторические </b>
          особенности города, но и поднимают <b>социальные</b> проблемы.</p>
      </div>
      <div className={s.row2}>
        <h3>На финальной презентации жюри выбрало 7 проектов-победителей</h3>
        <div className={s.row2_wrapper}>
          <h2>Эти проекты <br/> реализованы <br/> с помощью AR</h2>
        </div>
      </div>
      <div className={s.projects}>
        <ProjectCard
          img={img11}
          name={'Ангар'}
          photos={'win1'}
          team={'Команда Игоря Van Gee'}
          idea={'Превратить заброшенный ангар, находящийся на дороге из Мурино в Лаврики, в новое место притяжения.'}
          concept={` Для Мурино актуальна тема мультикультурности, поскольку здесь проживают люди из разных регионов 
          России. О традициях и особенностях их родных городов расскажут изображения на железных листах, из которых 
          сделан ангар. Арт-объект, как лоскутное одеяло, объединит всех жителей Мурино и станет местной 
          достопримечательностью.`}

        />
        <ProjectCard
          img={img12}
          name={'Рисунки у дома'}
          team={'Команда Полины Клешняк'}
          png={'#ED885C'}
          photos={'win2'}
          idea={'Украсить заборы вокруг дворов, школ и детских садов веселыми изображениями, вдохновленными рисункам детей'}
          concept={` Благодаря изображениям, сделанным из фанеры и акриловых красок, преобразятся невзрачные заборы 
          социальных учреждений Мурино. Также для популяризации проекта предполагается создание узнаваемого мерча: 
          участники уже изготовили тематический брелок и представили его членам жюри на защите.`}
        />
        <ProjectCard
          img={img13}
          name={'Прибавь яркости'}
          team={'Команда Кости Ужве'}
          photos={'win3'}
          idea={'Преобразовать невзрачные контейнерные площадки и трансформаторные будки в жилых районах Мурино.'}
          concept={`Для реализации члены жюри выбрали часть проекта, посвященную трансформаторным будкам, которые 
          будут облагорожены изображениями природы – такие объекты смогут украсить жилые районы и снизить проявления
           вандализма.`}
        />
        <ProjectCard
          img={img14}
          name={'Маскот'}
          team={'Команда Игоря Van Gee'}
          png={'#20BCD8'}
          photos={'win4'}
          idea={'Создать персонажа, который через взаимодействие с окружающей средой рассказал бы жителям города об их ' +
            'районе, подсветил его положительные стороны.'}
          concept={`Маскот “Мури” может быть реализован в разных форматах – как скульптура, настенный арт или стикер. 
          Его можно задействовать для популяризации полезных активностей – раздельного сбора мусора, субботников, 
          волонтерства и др. Изображение антропоморфного персонажа-маскота станет ориентиром для встреч, а размещенный 
          на нем QR-код поможет узнать исторические факты и полезную информацию о Мурино. Для этих целей команда 
          разработала пример сайта, с которым можно ознакомиться по ссылке: http://muri.tilda.ws.`}
        />
        <ProjectCard
          img={img15}
          name={'Муринские дачи'}
          team={'Команда Полины Клешняк'}
          photos={'win5'}
          idea={'Создать скворечники в виде исторических дач Мурино. '}
          concept={`Проект вдохновлен старинными дачными домами, сохранившимися в частном секторе Мурино с XIX века. 
          Создание серии скворечников в виде похожих домиков позволит показать, что многовековая история может 
          гармонично сочетаться с современными многоэтажками, природа — с цивилизацией. Уникальные скворечники позволят
           подчеркнуть идентичность города.`}
        />
        <ProjectCard
          img={img16}
          name={'Шарики'}
          team={'Команда Кости Ужве'}
          png={'#FCCD27'}
          photos={'win6'}
          idea={'Расположить в разных районах города арт-объекты в виде мячей для гольфа, характеризующие Мурино с положительных точек зрения.'}
          concept={`В прошлом на территории Мурино находилось созданное англичанами поле для гольфа. Красочные 
          арт-объекты в виде мячиков для игры отсылают к фактам из истории города, а также напоминают о его современных 
          культурных особенностях. Узнаваемые инсталляции должны поднять настроение местным жителям и создать позитивные 
          ассоциации с городом у его гостей. У арт-объектов есть и практическая функция: они могут стать новыми местами
           для встреч жителей.`}
        />
        <ProjectCard
          img={img17}
          name={'Игра'}
          team={'Команда Полины Клешняк'}
          photos={'win7'}
          idea={'Связать при помощи арт-объекта кварталы Мурино, разделенные железной дорогой. '}
          concept={`Железная дорога разделяет Мурино на западный и восточный районы, между которыми слабо развиты 
          транспортные и социальные связи. Муралы играющих в мяч мальчика и девочки появятся на фасадах домов, 
          расположенных по разные стороны от железнодорожных путей, олицетворяя отделенных друг от друга жителей. 
          В ночное время суток муралы будут дополняться световыми проекциями, связывающими районы символическим 
          жестом перебрасывания мяча.`}
        />

      </div>

      <div className={s.row3}>
        <div style={{position: 'relative'}}>
          <p>
            <span>Эти проекты <b>реализованы с помощью AR </b> </span>
            Чтобы посмотреть
            <b> проекты в AR </b>
            скачайте наше приложение
            <b> ARKA:</b>
          </p>
          <div className={s.buttons}>
            <a href={'https://apps.apple.com/ru/app/arka/id6449153717'} target={'_blank'} style={{left: '-10%'}}>
              <SvgSelector svg={'AppStore'}/>
            </a>
            <a href={'https://play.google.com/store/apps/details?id=com.pushkeen.arka'} target={'_blank'}
               style={{top: '0%'}}>
              <SvgSelector svg={'Google'}/>
            </a>
          </div>

        </div>
        <video
          src={appImg}
          typeof={'video/mp4'}
          controls={false}
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}/>
        {statusMobile &&
          <a href={'https://onelink.to/yb6ske'} target={'_blank'} style={{right: '-40%', position: 'relative'}}>
            <SvgSelector svg={'download'}/>
          </a>
        }

      </div>
      <h3>Другие проекты участников:</h3>
      <div className={s.projects}>
        <ProjectCard
          img={img21}
          name={'МурМурино'}
          team={'Команда Полины Клешняк'}
          photos={'proj1'}
          idea={'Обыграв название города, созвучное с кошачьим мурлыканьем, создать узнаваемый мерч и арт-объект, и таким образом сделать вклад в развитие бренда города.'}
          concept={`Предлагается создать карту Мурино с отмеченными на ней интересными местами и разместить ее на 
          стене. В темное время суток на карте будет появляться световая проекция в виде кошки. Участники также 
          создали примеры открыток и брелков на «кошачью» тематику. Так можно будет обыграть название города, 
          вызывающее ассоциации с кошками. `}
        />
        <ProjectCard
          img={img22}
          name={'Мурал «Гольфисты»'}
          team={'Команда Игоря Van Gee'}
          photos={'proj2'}
          idea={'Реализовать яркую роспись на стене жилищного комплекса, сюжет которой будет связан с историей города ' +
            '– с полем для гольфа, созданным на его территории в XIX веке приезжими англичанами.'}
          concept={`Яркий мурал с изображением гольфиста нацелен на то, чтобы привлечь внимание потенциальных жильцов 
          и СМИ к застройщику и внести вклад в создание позитивной городской среды.`}
        />
        <ProjectCard
          img={img23}
          name={'Гольфист'}
          team={'Команда Полины Клешняк'}
          png={'#FCCD27'}
          photos={'proj3'}
          idea={'Создать арт-объект из стали и акрила, который отсылал бы к истории города – ко временам, когда на его ' +
            'территории находилось созданное англичанами поле для гольфа.'}
          concept={`Арт-объект в виде гольфиста отсылает к прошлому, побуждая жителей и гостей города к знакомству 
          с историей Мурино. QR-коды, размещенные рядом с инсталляциями, позволят гражданам узнать больше информации 
          о городе и процессе его становления.`}
        />
        <ProjectCard
          img={img24}
          name={'Теплицы'}
          team={'Команда Кости Ужве'}
          photos={'proj4'}
          idea={'Создать инсталляции, нацеленные на решение проблем, с которыми сталкиваются жители города.'}
          concept={`Разместить арт-объекты в виде закрытых оранжерей, которые будут подсвечиваться по ночам. Таким 
          образом, можно будет решить проблемы недостаточной освещенности улиц города и отсутствия необходимого 
          количества зеленых насаждений. `}
        />
        <ProjectCard
          img={img25}
          name={'Башня Медвежки'}
          team={'Команда Игоря Van Gee'}
          photos={'proj5'}
          idea={'При помощи росписи сделать из заброшенного здания башни в восточном районе Мурино новую культурную достопримечательность.'}
          concept={`Башня Медвежки – пример инженерного мастерства XIX века, имеет историческую ценность. Согласно 
          задумке автора, предлагается преобразить башню при помощи яркой, абстрактной росписи, которая учитывает 
          особенности архитектуры здания. Новый арт-объект должен привлечь внимание местных жителей и гостей города.`}
        />
        <ProjectCard
          img={img26}
          name={'Cветлый  привет из прошлого'}
          team={'Команда Полины Клешняк'}
          photos={'proj6'}
          idea={'Арт-объект в виде мяча для гольфа должен помочь осветить дорогу в темное время суток и напомнить ' +
            'местным об историческом прошлом Мурино.'}
          concept={`На газоне по пути от станции метро Девяткино до начала Петровского бульвара планируется расположить 
          большой светящийся арт-объект в виде мяча для гольфа. Инсталляция поможет местным жителям с комфортом 
          перемещаться по дороге в темное время суток, а также напомнит прохожим о тех временах, когда на территории 
          Мурино располагалось поле для гольфа, созданное англичанами.`}
        />
        <ProjectCard
          img={img27}
          name={'Муринский знак'}
          team={'Команда Полины Клешняк'}
          png={'#ED885C'}
          photos={'proj7'}
          idea={'Установить на улицах города специальные знаки, которые будут указывать на названия популярных мест и ' +
            'рассказывать об историях жителей, связанных с ними.'}
          concept={`Интересные локации города зачастую известны только местным вследствие отсутствия дружественной 
          навигации. Интерактивные знаки, разработанные совместно с жителями Мурино, призваны решить эту проблему 
          благодаря созданию нового маршрута по интересным для гостей и знаковым для жителей города локациям. С 
          инсталляциями можно взаимодействовать по-разному: любой желающий может отправиться на поиски всех знаков; 
          или, благодаря текстовому сопровождению и QR-кодам на знаках, познакомиться с историями местных жителей.`}
        />
        {statusTablet &&
          <>
            <ProjectCard
              img={img28}
              name={'Прятки'}
              team={'Команда Кости Ужве'}
              photos={'proj8'}
              idea={'Благоустроить участок дороги от метро Девяткино до жилых домов через Лесной ручей.'}
              concept={`Превратить мощеный склон у ручья в динозавра: создание такого арт-объекта позволит привлечь 
              внимание жителей Мурино к проблеме загрязнения вод ручья. В том числе, арт-объект станет источником для 
              создания новых городских легенд о спящем динозавре.`}
            />
            <ProjectCard
              img={img29}
              name={'Точка силы'}
              team={'Команда Игоря Van Gee'}
              photos={'proj9'}
              idea={'Создать городское арт-пространство, уникальное для Мурино, которое станет достопримечательностью и местом рекреации для местных.'}
              concept={`Для арт-пространства выбрано популярное у местных поле между Мурино и Буграми, которое активно 
              используется жителями для встреч и прогулок. На его территории уже установлен один арт-объект – краб 
              стрит-артиста Вовы Net. В подражание участники предложили создать фигуру воксельного* кота, с которым 
              можно будет сфотографироваться, использовать его в качестве амфитеатра или превратить в площадку для 
              уличных культурных мероприятий. *воксельный – состоящий из трехмерных пикселей`}
            />
          </>
        }
      </div>
      {!statusTablet &&
        <div className={s.projects} style={{marginTop: '28px'}}>
          <ProjectCard
            img={img28}
            name={'Прятки'}
            team={'Команда Кости Ужве'}
            photos={'proj8'}
            idea={'Благоустроить участок дороги от метро Девяткино до жилых домов через Лесной ручей.'}
            concept={`Превратить мощеный склон у ручья в динозавра: создание такого арт-объекта позволит привлечь 
              внимание жителей Мурино к проблеме загрязнения вод ручья. В том числе, арт-объект станет источником для 
              создания новых городских легенд о спящем динозавре.`}
          />
          <ProjectCard
            img={img29}
            name={'Точка силы'}
            team={'Команда Игоря Van Gee'}
            photos={'proj9'}
            idea={'Создать городское арт-пространство, уникальное для Мурино, которое станет достопримечательностью и местом рекреации для местных.'}
            concept={`Для арт-пространства выбрано популярное у местных поле между Мурино и Буграми, которое активно 
              используется жителями для встреч и прогулок. На его территории уже установлен один арт-объект – краб 
              стрит-артиста Вовы Net. В подражание участники предложили создать фигуру воксельного* кота, с которым 
              можно будет сфотографироваться, использовать его в качестве амфитеатра или превратить в площадку для 
              уличных культурных мероприятий. *воксельный – состоящий из трехмерных пикселей`}
          />
        </div>
      }
    </div>
  );
};

export default Block5;