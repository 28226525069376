import s from "./title.module.scss";
import "styles/variables.scss";

export const Title = ({ children }) => {
  return (
    <h2 data-aos="fade-left" className={s.title}>
      {children}
    </h2>
  );
};
