import { Title } from "components/Title/Title";
import s from "./map.module.scss";
import "styles/variables.scss";

export const Map = () => (
  <section className={s.wr} id={"contact"}>
    <Title>площадка</Title>
    <div className={s.content}>
      <div>
        <p>
          Молодежный коворкинг центр МуриУМ: Екатерининская улица, 8к1, Мурино
        </p>
        <p>
          Центр Муниципальных услуг: <br /> Парковая улица, 1А, Мурино
        </p>
      </div>
      <iframe
        title="map"
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A3fd92e25d9529b8607f787ac2a14c1dfb6a463d281a5929bd9350b4975fd2fd5&amp;source=constructor"
        width="829"
        height="524"
        frameborder="0"
      ></iframe>
    </div>
  </section>
);
