import { Title } from "components/Title/Title";
import {
  Excursions,
  Lections,
  MasterClasses,
  Performances,
} from "./ProgramSliders/ProgramSliders";
import s from "./program.module.scss";

const Program = () => {
  return (
    <section className={s.wr} id={"programs"}>
        <Title>
          Программа
          {/* <SvgSelector svg={"program-arrow"} /> */}
        </Title>
        <Lections />
        <MasterClasses />
        <Excursions />
        <Performances />
    </section>
  );
};

export default Program;
