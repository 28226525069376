import { useEffect, useState } from "react";
import s from "./arrowUp.module.scss";
import SvgSelector from "components/SvgSelector";
import cn from "classnames";

export const ArrowUp = () => {
  const [isVisible, setVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 400) {
      setVisible(true);
    } else if (window.scrollY < 400) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <button
      className={cn(s.arrow, isVisible ? "" : s.arrow_hidden)}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
    >
      <SvgSelector svg={"arrow-up"} />
    </button>
  );
};
