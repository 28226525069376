import { useMediaQuery } from "usehooks-ts";
import s from "./merch.module.scss";
import { Wrapper } from "components/Wrapper/Wrapper";

const Merch = () => {
  const isMobile = useMediaQuery(`(max-width: 520px)`);
  return (
    <section className={s.wr}>
      <Wrapper>
        <div className={s.container}>
          <header>
            <h2>мерч</h2>
            <p>
              Совместно с уличным художником <br /> <span>Demos </span>
              создали мерч для проекта
            </p>
          </header>
          <main>
            <img
              className={s.m_1}
              src={require("assets/merch/1.png")}
              alt="Мерч"
            />

            <img
              className={s.m_2}
              src={require(`assets/merch/2${isMobile ? "-mobile" : ""}.png`)}
              alt="Мерч"
            />
            <img
              className={s.m_3}
              src={require("assets/merch/3.png")}
              alt="Мерч"
            />
          </main>
          <a
            href="https://setkalavka.ru/art"
            target="_blank"
            rel="noopener noreferrer"
            className={s.link}
          >
            Хочу мерч!
          </a>
        </div>
      </Wrapper>
    </section>
  );
};

export default Merch;
