export const lections = [
  {
    date: new Date(2023, 9, 28, 15, 0),

    url: "https://publicart.timepad.ru/event/2620072/",
    title: "Официальное закрытие проекта",
    text: `Публичная презентация и защита итоговых арт-проектов от креативных групп. Подведение итогов Паблик-арт лаборатории.`,
  },
  {
    date: new Date(2023, 9, 17, 19, 3),

    url: "https://publicart.timepad.ru/event/2615017/",
    title: "Как стрит-арт стал самым демократичным искусством?",
    // author: "Рома OUFX",
    text: `Вместе с Семеном Некрасовым ответим на поставленный вопрос, изучив путь уличного искусства от интервенции к соучастию.`,
  },
  {
    date: new Date(2023, 9, 1, 17, 0),

    url: "https://publicart.timepad.ru/event/2604234/",
    title: 'Открытие "Паблик-арт лаборатории"',
    // author: "Рома OUFX",
    text: `Открытие проекта Паблик-арт лаборатории. Паблик-ток с художниками, презентация идеи и обсуждение особенностей территории.`,
  },
  {
    date: new Date(2023, 9, 5, 19, 45),

    url: "https://publicart.timepad.ru/event/2608738/",
    title: "Уличное искусство в России – вызов или попытка диалога?",
    // author: "Рома OUFX",
    text: `Вместе с Анастасией Прониной обсудим реакцию публики на паблик-арт и стрит-арт объекты, а также проекты, направленные на вовлечение жителей в процесс их создания.
`,
  },
  {
    date: new Date(2023, 9, 8, 15, 0),

    url: "https://publicart.timepad.ru/event/2608235/",
    title: "Стрит-арт и классическое искусство: в поисках параллелей",
    // author: "Рома OUFX",
    text: `Любой вид искусства существует в русле традиции и стрит-арт не является исключением. Вместе с искусствоведом проведем параллели уличного искусства с мировым.`,
  },
];
export const masterClasses = [
  {
    date: new Date(2023, 9, 18, 19, 0),

    url: "https://publicart.timepad.ru/event/2618529/",
    title: "Абстрактная живопись от Ромы UOFX",
    // author: "Рома OUFX",
    text: `Вместе с художником Ромой UOFX создаем арт-объект. Узнаем, как работать с абстрактными формами и подбирать правильные цветовые сочетания.`,
  },
  {
    date: new Date(2023, 9, 14, 17, 0),

    url: "https://publicart.timepad.ru/event/2618675/",
    title: "Конкретный замес",
    // author: "Рома OUFX",
    text: `Мастер-класс по созданию скульптур из глины вместе с Алексом Ко.`,
  },
  {
    date: new Date(2023, 9, 7, 15, 0),

    url: "https://publicart.timepad.ru/event/2608730/",
    title: "Один тег вместо тысячи слов",
    // author: "Рома OUFX",
    text: `Создание своего тега с Artpogallery. Вместе с уличным художником придумаете свой тег, разобравшись в шрифтовых стилях, а при желании усложните его собственным персонажем.`,
  },
  {
    date: new Date(2023, 9, 7, 17, 0),

    url: "https://publicart.timepad.ru/event/2614959/",
    title: "Коллажная техника с А. Юджином.",
    // author: "Рома OUFX",
    text: `Поговорим об искусстве коллажа, как и где егоможно использовать. Научимся искать новые формы и смыслы в старом дизайне и создадим свой уникальный неоколлаж.`,
  },
  {
    date: new Date(2023, 9, 14, 15, 0),

    url: "https://publicart.timepad.ru/event/2618345/",
    title: "“Арт из кирпича” со Стасом Багсом.",
    // author: "Рома OUFX",
    text: `Создадите свой первый уличный арт-объект — кирпич и научитесь трафаретной технике с использованием аэрозольных баллончиков.`,
  },
];

export const excursions = [
  {
    date: new Date(2023, 9, 22, 14, 3),

    url: "https://publicart.timepad.ru/event/2619168/",
    title: "Мультицентр: моя Вселенная",
    // author: "Рома OUFX",
    text: `Экскурсия по работам фестиваля. Расскажем о том, как социальные проекты и уличное искусство помогают друг другу и покажем, что из этого получается.`,
  },
  {
    date: new Date(2023, 9, 22, 13, 0),

    url: "https://publicart.timepad.ru/event/2619160/",
    title: "Мультицентр: моя Вселенная",
    // author: "Рома OUFX",
    text: `Экскурсия по работам фестиваля. Расскажем о том, как социальные проекты и уличное искусство помогают друг другу и покажем, что из этого получается.`,
  },
  {
    date: new Date(2023, 9, 31, 16, 0),

    url: "https://publicart.timepad.ru/event/2619174/",
    title: "Мемориальные практики в уличном искусстве",
    // author: "Рома OUFX",
    text: `Пройдемся от Сада Пашкова до “Пушкинской-10”, поговорим о памяти в уличном искусстве, проведем его сравнение с классическими памятниками и исторической архитектурой.`,
  },
  {
    date: new Date(2023, 9, 21, 14, 0),

    url: " https://publicart.timepad.ru/event/2619159/",
    title: "Культурный квартал Брусницын",
    // author: "Рома OUFX",
    text: `Посетим Брусницын квартал и выясним, какую роль паблик-арта играет в переосмыслении исторических пространств города.`,
  },
  {
    date: new Date(2023, 9, 13, 14, 0),

    url: "https://publicart.timepad.ru/event/2619137/",
    title: "Культурный квартал Брусницын",
    // author: "Рома OUFX",
    text: `Посетим Брусницын квартал и выясним, какую роль паблик-арта играет в переосмыслении исторических пространств города.`,
  },
  {
    date: new Date(2023, 9, 11, 14, 3),

    url: "https://publicart.timepad.ru/event/2616542/",
    title: "Стрит-арт – часть городского ландшафта. Петроградка",
    // author: "Рома OUFX",
    text: `Маршрут экскурсии будет пролегать от павильонов Ленфильма до легендарной котельной Камчатка, в которой работал Виктор Цой. Вы познакомитесь с именами главных городских художников и историями создания отдельных произведений.`,
  },
  {
    date: new Date(2023, 9, 5, 16, 3),

    url: "https://publicart.timepad.ru/event/2615036/#register",
    title: "Мемориальные практики в уличном искусстве",
    // author: "Рома OUFX",
    text: `Поговорим о ключевых уличных художниках Петербурга, их работе с городским контекстом и новыми художественными формами.`,
  },
  {
    date: new Date(2023, 9, 7, 15, 0),
    url: "https://publicart.timepad.ru/event/2614997/",
    title: "«Нетипичный Невский: уличное искусство в городской среде»",
    // author: "Рома OUFX",
    text: `Поговорим о ключевых уличных художниках Петербурга, их работе с городским контекстом и новыми художественными формами.`,
  },
  {
    date: new Date(2023, 9, 8, 15, 0),

    url: "https://publicart.timepad.ru/event/2615010/",
    title: "«Нетипичный Невский: уличное искусство в городской среде»",
    // author: "Рома OUFX",
    text: `Поговорим о ключевых уличных художниках Петербурга, их работе с городским контекстом и новыми художественными формами.`,
  },
];
export const performances = [
  // {
  //   date: new Date(2023, 9, 11, 0, 0),

  //   url: "https://publicart.timepad.ru/event/2619737/",
  //   title: "Из троллейбуса в арт-объект  — социальный проект",
  //   // author: "Рома OUFX",
  //   text: `Приглашаем посмотреть на создание росписи в магазине комиксов Just4Fun. От наброска до готового результата за один день.`,
  // },
  {
    date: new Date(2023, 9, 11, 0, 0),

    url: "https://publicart.timepad.ru/event/2619737/",
    title: "Покрас от “А до Я” вместе с Игорем Van Gee",
    // author: "Рома OUFX",
    text: `Приглашаем посмотреть на создание росписи в магазине комиксов Just4Fun. От наброска до готового результата за один день.`,
  },

  {
    date: new Date(2023, 9, 1, 13, 0),

    url: "https://publicart.timepad.ru/event/2604234/",
    title: "Лаборатория паблик-арта глазами художников",
    // author: "Рома OUFX",
    text: `Художники-менторы в день открытия проекта продемонстрируют свой стиль, создав росписи в режиме реального времени в Муринском парке.`,
  },
];
