import React from 'react';
import s from './block4.module.scss'
import video from "../../assets/blocks/block4.mov";
import art1 from '../../assets/blocks/art1.png'
import art2 from '../../assets/blocks/art2.png'
import art3 from '../../assets/blocks/art3.png'
import Team from "../Team/Team";

const group1 = [
  'Шахова Анастасия',
  'Козлова Екатерина',
  'Хайманова Кристина',
  'Рядовкина Арина',
  'Аманова Алина',
  'Катаругин Дмитрий',
  'Журавель Екатерина',
  'Прокопенко Екатерина',
]
const group2 = [
  'Шахова Лейла',
  'Шмелева Екатерина',
  'Блехан Юлия',
  'Петухов Василий',
  'Пытляк Мария',
  'Бетехтин Кирилл',
  'Бреверн Анна',
  'Шейда Анна',
  'Князева Софья',
]
const group3 = [
  'Савина Нана',
  'Салихова Айгуль',
  'Игумнова Екатерина',
  'Нуриева Карина',
  'Михайлова-Яровая Евгения',
  'Филимонова Ольга',
  'Резепкина-Бойко Илона',
  'Сотавова Валерия',
  'Андреева Анастасия',
]

const Block4 = () => {

  return (
    <div className={s.wrapper}>
      <h1>креативные группы</h1>
      <div className={s.row1}>
        <div className={s.row1_left}>
          <p>
            Мы собрали <b>39 творческих</b>  ребят разных профессий: <b>от художников до инженеров</b>
          </p>
          <p>
            <b>За месяц</b> они прошли путь из <b>7 встреч,</b>  которые включали в себя:
          </p>
        </div>
        <div className={s.row1_right}>
          <video
            src={video}
            //type={"video/mp4"}
            typeof={'video/mp4'}
            controls={false}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
          />
          <ul>
            <li>обсуждение концепций</li>
            <li>стрит-арт хантинг <br/> с подбором локаций</li>
            <li>творческие мастер-классы для <br/> погружения в уличную культуру</li>
            <li>разработку проектов, <br/> подготовку презентаций</li>
          </ul>
        </div>
      </div>
      <div className={s.row2}>
        <p>На каждом этапе участников сопровождали <br/> <b>художники-менторы:</b> </p>
        <div className={s.teams}>
          <Team
            art={'Костя Ужве'}
            img={art1}
            members={group1}
            rev={true}
            id={'team1'}
          />
          <Team
            art={'Полина Клешняк'}
            img={art2}
            members={group2}
            id={'team2'}
          />
          <Team
            art={'Игорь Van Gee'}
            img={art3}
            members={group3}
            pos={true}
            rev={true}
            id={'team3'}
          />
        </div>
      </div>
    </div>
  );
};

export default Block4;