import {Wrapper} from "../components/Wrapper/Wrapper";
import {useEffect, useState} from "react";
import {useMediaQuery} from "@mui/material";
import Program from "components/Program/Program";
import {Map} from "components/Map/Map";
import {ArrowUp} from "components/ArrowUp/ArrowUp";
import Aos from "aos";
import {Partners} from "components/Partners/Partners";
import Block1 from "../components/Block1/Block1";
import Block2 from "../components/Block2/Block2";
import Block4 from "../components/Block4/Block4";
import Block5 from "../components/Block5/Block5";
import Tabs from "components/Tabs/Tabs";
import {Smi} from "components/Smi/Smi";
import Merch from "components/Merch/Merch";

const Index = () => {
  const [showMore, setShowMore] = useState(false);
  const statusMobile = useMediaQuery(`(max-width: 440px)`);
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <section>
      <Wrapper>
        <Block1/>
        <Block2/>
      </Wrapper>
      <Tabs/>
      <Wrapper>
        <Block4/>
        <Block5/>
      </Wrapper>
      <Merch/>
      <Wrapper>
        <Smi/>
        <Program/>
        <Partners/>
        <Map/>
      </Wrapper>
      <ArrowUp/>
    </section>
  );
};

export default Index;
