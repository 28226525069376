import React from 'react';
import video from '../../assets/blocks/block1.mov'
import s from './block1.module.scss'
const Block1 = () => {
  return (
    <div className={s.wrapper} id={'main_heading'}>
      <div className={s.title}>
        <h1>Лаборатория</h1>
        <div className={s.title_row2}>
          <h1>паблик-арта</h1>
          <a href={'https://pushkeen.ru/projects/publicart'} target={'_blank'}>
            <h3>от PublicARt</h3>
          </a>
        </div>
      </div>

      <div className={s.row2}>
        <h4>Первый проект для погружения жителей Мурино в уличное искусство</h4>
        <a href={'#aboutUs'}>
          <h3>разобраться<br/>в вопросе</h3>
        </a>
      </div>

      <video
        src={video}
        //type={"video/mp4"}
        typeof={'video/mp4'}
        controls={false}
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
      />

      <div className={s.row3}>
        <h2 id={'aboutUs'}>Для этого проекта уличное искусство выбрано неслучайно</h2>
        <div className={s.row3_text}>
          <p>
            <b>Паблик-арт</b> — это искусство в общественном пространстве, согласованное с властями города, включающее
            любые
            формы, от скульптуры до перформанса.
          </p>
          <p>
            <b>Лаборатория Паблик-арта</b> выстраивает диалог между современным уличным искусством и жителями города при
            помощи различных активностей — <b>мастер-классов, экскурсий, лекций, перформансов.</b>
          </p>
        </div>
      </div>

    </div>


  );
};

export default Block1;