import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import s from "./slider.module.scss";
import { Navigation, Keyboard, Mousewheel } from "swiper/modules";
import { sliderData } from "./data";

export const TabSlider = ({ dataIndex }) => {
  return (
    <Swiper
      loop={true}
      keyboard={{
        enabled: true,
      }}
      mousewheel={{
        releaseOnEdges: false,
        enabled: true,
        sensitivity: 4,
      }}
      modules={[Keyboard, Navigation, Mousewheel]}
      spaceBetween={"23"}
      navigation={true}
      className={s.slider}
    >
      {sliderData[dataIndex].map((imgUrl, i) => (
        <SwiperSlide className={s.slide} key={i}>
          <img
            src={require("assets/tabs/sliders" +
              `/${dataIndex}/` +
              `${imgUrl}`)}
            alt=" "
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
