export const sliderData = {
  win1: ["video.mp4","img1.png", "img2.png"],
  win2: ["video.MOV","img1.png", "img2.png","img3.png","img4.png","img5.png",],
  win3: ["video.MOV","img1.png", "img2.png","img3.png",],
  win4: ["video.MOV","img1.png", "img2.png","img3.png","img4.png","img5.png",],
  win5: ["video.MOV","img1.png", "img2.png","img3.png",],
  win6: ["video.MOV","img1.png", "img2.png","img3.png","img4.png","img5.png","img6.png",],
  win7: ["video.mp4","img1.png", "img2.png","img3.png",],
  proj1: ["img1.png", "img2.png","img4.png","img5.png",],
  proj2: ["img1.png", "img2.png",],
  proj3: ["img1.png", "img2.png","img3.png",],
  proj4: ["img1.png", "img2.png","img3.png","img4.png"],
  proj5: ["img1.png", "img2.png",],
  proj6: ["img1.png", "img2.png",],
  proj7: ["img1.png", "img2.png","img3.png",],
  proj8: ["img1.png", "img2.png",],
  proj9: ["img1.png", "img2.png",],
};
