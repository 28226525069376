import { Title } from "components/Title/Title";
import s from "./smi.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const smi = [
  {
    label: "ЛЕН ТВ 24",
    img: "lentv.jpg",
    link: "https://lentv24.ru/ziteli-murino-predstavili-kreativnye-proekty-blagoustroistva-goroda.htm",
  },
  {
    label: "Модное Радио",
    img: "radio.jpg",
    link: "https://vk.com/wall-54867651_21173",
  },
  {
    label: "Mash на Мойке",
    img: "mash.jpg",
    link: "https://vk.com/wall-179189462_1295110",
  },
];

export const Smi = () => (
  <section className={s.wr}>
    <Title>проект в сми</Title>
    <Swiper
      breakpoints={{
        900: {
          spaceBetween: "46",
          slidesPerView: 3,
        },
      }}
      spaceBetween={"20"}
      slidesPerView={"auto"}
      className={s.slider}
    >
      {smi.map((card, i) => (
        <SwiperSlide key={i}>
          <div className={s.card}>
            <img
              className={s.card_img}
              src={require("assets/smi/" + card.img)}
              alt={card.label}
            />
            <div className={s.card_footer}>
              <h3>{card.label}</h3>
              <a href={card.link} target="_blank" rel="noopener noreferrer">
                Посмотреть
              </a>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </section>
);
