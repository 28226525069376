 import React, {useState} from 'react';
import s from './header.module.scss'
import SvgSelector from "../SvgSelector";
import {useMediaQuery} from "@mui/material";

const Header = () => {
  const statusMobile = useMediaQuery(`(max-width: 520px)`);
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  if (statusMobile) {
    return ( //--------------------------------mobile
      <header style={{height: burgerIsOpen ? 230 : 38}} className={s.header}>
        <div className={s.visible}>
          <a className={s.logo} href={'#main_heading'}>
            Лаборатория
          </a>
          <button
            onClick={() => setBurgerIsOpen(!burgerIsOpen)}
          >
            <SvgSelector svg={'burger'}/>
          </button>
        </div>

        <div className={s.titles}>
          <a href={'#aboutUs'} onClick={() => setBurgerIsOpen(false)}><h3>О проекте</h3></a>
          <div className={s.line}/>
          <a href={'#programs'} onClick={() => setBurgerIsOpen(false)}><h3>Программа</h3></a>
          <div className={s.line}/>
          <a href={'#contact'} onClick={() => setBurgerIsOpen(false)}><h3>контакты</h3></a>
        </div>

      </header>
    );
  } else {
    return ( //--------------------------------desktop
        <header className={s.header}>
          <div className={s.wrapper}>
            <a className={s.logo} href={'#main_heading'}>
              Лаборатория
            </a>
            <div className={s.titles}>
              <a href={'#aboutUs'}><h3>О проекте</h3></a>
              <a href={'#programs'}><h3>Программа</h3></a>
              <a href={'#contact'}><h3>контакты</h3></a>
            </div>
          </div>
        </header>
    );
  }
};

export default Header;