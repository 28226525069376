import s from "./programCard.module.scss";

const ProgramCard = ({ data, buttonColor }) => {
  const time = data.date.getHours() + ":" + data.date.getMinutes() + "0";
  return (
    <section className={s.card}>
      <div className={s.header}>
        <div>
          <span className={s.date}>
            {data.date.getDate() +
              "." +
              (data.date.getMonth() >= 9
                ? Number(data.date.getMonth() + 1)
                : "0" + Number(data.date.getMonth() + 1))}
          </span>
          <span className={s.time}>
            {time.length > 5 ? time.slice(0, -1) : time === "0:00" ? "" : time}
          </span>
        </div>
        <a
          className={
            data.date < Date.now() || data.url === "скоро" ? s.inactive : ""
          }
          style={{
            backgroundColor: buttonColor,
            color: buttonColor === "#262626" ? "#fff" : "#262626",
          }}
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.url === "скоро"
            ? "скоро"
            : data.date < Date.now()
            ? "архив"
            : "записаться"}
        </a>
      </div>
      <main>
        <h4>{data.title}</h4>
        {/* <span>{data.author}</span> */}
        <p>{data.text}</p>
      </main>
    </section>
  );
};

export default ProgramCard;
