import s from "./programSliders.module.scss";
import { Slider } from "./Slider";
import { excursions, lections, masterClasses, performances } from "./data";
import cn from "classnames";

export const Lections = () => {
  return (
    <StateWrapper initial={lections} color={"#20BCD8"}>
      <p data-aos="fade-left" className={cn(s.name, s.name_lect)}>
        Лекции
      </p>
    </StateWrapper>
  );
};
export const MasterClasses = () => {
  return (
    <div className={cn(s.paint, s.paint_master)}>
      <StateWrapper indent={false} initial={masterClasses} color={"#F07642"}>
        <p data-aos="fade-left" className={cn(s.name, s.name_mk)}>
          мастер-классы
        </p>
      </StateWrapper>
    </div>
  );
};
export const Excursions = () => {
  return (
    <div className={cn(s.paint, s.paint_exc)}>
      <StateWrapper initial={excursions} color={"#FDE401"}>
        <p data-aos="fade-left" className={cn(s.name, s.name_exc)}>
          экскурсии
        </p>
      </StateWrapper>
    </div>
  );
};

export const Performances = () => {
  return (
    <StateWrapper indent={false} initial={performances} color={"#262626"}>
      <p data-aos="fade-left" className={cn(s.name, s.name_perf)}>
        перформансы
      </p>
    </StateWrapper>
  );
};

const StateWrapper = ({ initial, children, color, indent = false }) => {
  const arrCurrent = moveNotPast(initial).sort((a, b) => a.date - b.date);
  const arrPast = movePast(initial).sort((a, b) => b.date - a.date);
  const data = [...arrCurrent, ...arrPast];

  return (
    <div className={s.wr}>
      {children}
      <Slider color={color} indent={indent} data={data} />
    </div>
  );
};

function movePast(arr) {
  return arr.filter((el) => el.date < Date.now());
}
function moveNotPast(arr) {
  return arr.filter((el) => el.date >= Date.now());
}
