import SvgSelector from "components/SvgSelector";
import s from "./footer.module.scss";
import "styles/variables.scss";
import { Wrapper } from "components/Wrapper/Wrapper";

export const Footer = () => {
  return (
    <footer className={s.footer}>
      <Wrapper>
        <div className={s.footer_wrapper}>
          <a href="#main_heading" className={s.logo}>
            <SvgSelector svg={"logo-lab-text"} />
          </a>
          <div className={s.address}>
            <a
              href="https://yandex.ru/maps/-/CDUGbT9v"
              target="_blank"
              rel="noopener noreferrer"
            >
              Молодежный коворкинг центр <br /> МуриУМ: Екатерининская улица,
              8к1, Мурино
            </a>
            <a
              href="https://yandex.ru/maps/-/CDUGfE1D"
              target="_blank"
              rel="noopener noreferrer"
            >
              Центр Муниципальных услуг: <br /> Парковая улица, 1А, Мурино
            </a>
          </div>
          <div className={s.social}>
            <a
              href="https://vk.com/publicartspb"
              target="_blank"
              rel="noopener noreferrer"
            >
              VK
            </a>
            <a
              href="https://t.me/publicartspb"
              target="_blank"
              rel="noopener noreferrer"
            >
              telegram
            </a>
            <a
              href="https://pushkeen.ru/projects/publicart"
              target="_blank"
              rel="noopener noreferrer"
            >
              public&nbsp;art
            </a>
          </div>
          <a
            className={s.pushkeen}
            href="https://pushkeen.ru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Разработано компанией PushKeen
          </a>
        </div>
      </Wrapper>
    </footer>
  );
};
