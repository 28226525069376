import Header from "./components/Header/Header";
import Index from "./index/Index";
import {Footer}  from "components/Footer/Footer.js";


function App() {
  return (
    <div className="App">
      <Header />
      <Index />
      <Footer />
    </div>
  );
}

export default App;
