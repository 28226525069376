import ProgramCard from "../ProgramCard/ProgramCard";
import { Swiper, SwiperSlide } from "swiper/react";
import s from "./programSliders.module.scss";
import "swiper/css";

import { Keyboard } from "swiper/modules";
import cn from "classnames";
import { useCallback, useRef } from "react";
import SvgSelector from "components/SvgSelector";

export const Slider = ({ data, cl = "", indent = false, color }) => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <Swiper
      ref={sliderRef}
      keyboard={{
        enabled: true,
      }}
      modules={[Keyboard]}
      spaceBetween={"23"}
      slidesPerView={"auto"}
      className={cn("slider", cl, indent && "slider-indent")}
    >
      {data.map((card, i) => (
        <SwiperSlide key={i}>
          <ProgramCard buttonColor={color} data={card} />
        </SwiperSlide>
      ))}
      <button className={s.prev} onClick={handlePrev}>
        <SvgSelector svg={"arrow-prev"} />
      </button>
      <button className={s.next} onClick={handleNext}>
        <SvgSelector svg={"arrow-prev"} />
      </button>
    </Swiper>
  );
};
