import { useState } from "react";
import s from "./tabs.module.scss";
import cn from "classnames";
import { Wrapper } from "components/Wrapper/Wrapper";
import { TabSlider } from "./TabsSlider/TabsSlider";

const list = [
  ["300", "регистраций"],
  ["31", "мероприятие"],
  ["10", "художников"],
  ["3", "арт-объекта"],
];

const Tabs = () => {
  const [tab, setTab] = useState(0);
  return (
    <section className={s.wr}>
      <Wrapper>
        <div className={s.container}>
          <ul className={s.list}>
            {list.map((item, i) => (
              <button
                className={cn(s.list_item, tab === i && s.list_item__active)}
                key={i}
                onClick={() => {
                  setTab(i);
                }}
              >
                <span>{item[0]}</span>
                <span>{item[1]}</span>
              </button>
            ))}
          </ul>
          <TabSlider dataIndex={tab} />
        </div>
      </Wrapper>
    </section>
  );
};

export default Tabs;
