import { Title } from "components/Title/Title";
import s from "./partners.module.scss";
import SvgSelector from "components/SvgSelector";

export const Partners = () => (
  <section className={s.wr}>
    <Title>организаторы</Title>
    <div className={s.organizators}>
      <span>
        Благотворительный фонд <br />
        «Добрый знак»
      </span>
      <SvgSelector svg={"logo-publicart-partners"} />
    </div>
    <Title>Партнеры</Title>
    <div className={s.partners}>
      <div>
        <PartnerLink url={"https://polis-group.ru/"} image={"polis.png"} />
        <PartnerLink
          url={"https://администрация-мурино.рф"}
          image={"murino.png"}
        />
        <PartnerLink
          url={"https://открытыйгород.рф/"}
          image={"open-city.png"}
        />
      </div>
      <div>
        <PartnerLink url={"https://vk.com/coffeeslon"} image={"slon.png"} />
        {/* <PartnerLink url={"https://i-active.space/"} image={"interactive.png"} /> */}
        <PartnerLink url={"https://mcsutu.vsite.biz/"} image={"mcsutu.png"} />
        <PartnerLink url={"https://vk.com/j4fun"} image={"justfun.png"} />
        <PartnerLink url={"https://t.me/Torii_anime"} image={"tor.png"} />
      </div>
    </div>
    <div className={s.partners_label}>
      Проект реализован при поддержке «Комитета общественных коммуникаций
      Ленинградской области», «Команда47»
    </div>
    <div className={s.partners_label__flex}>
      <img src={require("assets/partners/grant.png")} />
      <img src={require("assets/partners/47.png")} />
    </div>
  </section>
);

const PartnerLink = ({ url, image }) => (
  <a
    className={s.partner_link}
    href={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={require("assets/partners/" + image)} />
  </a>
);
